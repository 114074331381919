@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,700&display=swap");

/* Variables */
:root {
  --font-family: "Roboto", sans-serf;
  --normal-font: 400;
  --bold-font: 700;
  --bolder-font: 900;
  --bg-color: white;
  --primary-color: #00cec0;
  --secondary-color: #ff7235;
  --primary-shadow: #8b8eaf;
  --secondary-shadow: #a17a69;
  --bottom-margin: 0.5rem;
  --bottom-margin-2: 1rem;
  --line-height: 1.7rem;
  --transition: 0.3s;
}

html {
  scroll-behavior: smooth;
}

/* CSS Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

body {
  font-family: var(--font-family);
}

/* Navigation Bar */
nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3.5rem;
  background-color: var(--primary-color);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

nav h1 {
  color: var(--bg-color);
}

nav a {
  color: var(--bg-color);
  transition: var(--transition);
}
nav a:hover {
  border-bottom: 2px solid var(--bg-color);
}

nav ul {
  display: flex;
  gap: 1.9rem;
}

nav ul li {
  font-weight: var(--bold-font);
}

/* About Me */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  max-width: 150rem;
  margin-top: 50px;
}

.bio {
  width: 50rem;
  padding: 0.625rem;
  border-radius: 6px;
  box-shadow: 0px 2px 15px 2px var(--primary-shadow);
}

.bio h1 {
  margin-bottom: var(--bottom-margin);
}

.bio p {
  line-height: var(--line-height);
  padding: 0.3rem 0;
}

/* Projects */
.projects {
  background-color: var(--bg-color);
  padding: 32px 0;
  margin-top: 2rem;
}

.project-pic {
  width: 65%;
  height: 60%;
}

.projects-container {
  align-items: center;
  justify-content: center;
}

.projects-title {
  text-align: center;
  margin-bottom: 1rem;
}

.project-container {
  text-align: center;
  width: 30rem;
  padding: 1rem;
}

.project-container p {
  padding: 0.4rem;
}

.project-title {
  margin-bottom: var(--bottom-margin);
}

.project-details {
  margin-bottom: var(--bottom-margin);
}

.project-card {
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 3px 10px var(--primary-shadow);
  padding: 20px;
  margin: 10px;
}

/* Certificates Section */
.certificates {
  background-color: var(--bg-color);
  padding: 32px 0;
  margin-top: 2rem;
}

.certificate-pic {
  width: 90%;
  height: 85%;
}

.certificates-container {
  align-items: center;
  justify-content: center;
}

.certificates-title {
  text-align: center;
  margin-bottom: 1rem;
}

.certificate-container {
  text-align: center;
  width: 40rem;
  padding: 1rem;
}

.certificate-title {
  margin-bottom: var(--bottom-margin);
}

.certificate-card {
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 3px 10px var(--primary-shadow);
  padding: 20px;
  margin: 10px;
}

/* Contact Section */
.contact {
  margin-top: 2rem;
}

.contact h2 {
  text-align: center;
  margin-bottom: var(--bottom-margin-2);
}

.contact-form-container {
  max-width: 40.75rem;
  margin: 0 auto;
  padding: 0.938rem;
  border-radius: 5px;
  box-shadow: 0 3px 10px var(--secondary-shadow);
}

.contact-form-container label {
  line-height: 2.7em;
  font-weight: var(--bold-font);
  color: var(--primary-color);
}

.contact-form-container textarea {
  min-height: 6.25rem;
  font-size: 14px;
}

.contact-form-container .input-field {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  border: 2px outset var(--primary-color);
  font-size: 0.875rem;
  outline: none;
}

.input-field::placeholder {
  padding: 0.5rem;
  color: var(--primary-color);
}

.submit-btn {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: var(--bold-font);
  transition: var(--transition);
}

.submit-btn:hover {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  cursor: pointer;
}

/* Social Accounts */
.socials {
  display: flex;
  justify-content: center;
  bottom: 50%;
  margin-top: 30px;
}

.socicon {
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
  margin-right: 10px;
}

/* Media Queries */
@media screen and (max-width: 720px) {
  nav {
    padding: 1.5rem 1rem;
  }

  .bio {
    width: 30rem;
  }

  .projects-container {
    flex-direction: column;
  }

  .project-container {
    width: 21rem;
  }

  .certificate-container {
    width: 25rem;
  }

  .contact-form-container {
    max-width: 23rem;
  }
}

@media screen and (max-width: 420px) {
  nav {
    padding: 1.5rem 0.5rem;
  }

  .bio {
    width: 18rem;
  }

  .project-container {
    width: 18rem;
  }

  .certificate-container {
    width: 20rem;
  }

  .contact-form-container {
    max-width: 19rem;
  }
} 